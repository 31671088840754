import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const QueryInterface = lazy(() => import("./pages/queryInterface"));
const CodeReview = lazy(() => import("./pages/codeReview"));
const HomePanel = lazy(() => import("./pages/homePanel"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/homePanel" element={<HomePanel />} />
        <Route path="/queryInterface" element={<QueryInterface />} />
        <Route path="/codeReview" element={<CodeReview />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
